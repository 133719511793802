import * as React from 'react'
import styled from '@emotion/styled'
import { IoMdCall } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import { FormattedMessage } from 'react-intl';

import {Link} from "gatsby";
import {fonts} from "../../styles/variables";
import LocalizedLink from "../int/LocalizedLink";
const StyledLink = styled(LocalizedLink)`
`

const StyledDiv = styled.div`

  width: 100%;
text-align:center;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:2em;
font-family: "Theano Didot", Regular;
font-size: 1.22em;
letter-spacing: 1px;



`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 180%;
text-align: right;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:100%;



`;


const StyledPhoneIcon = styled(IoMdCall)`
width:26px;
height:26px;
transition: all .7s ease;
margin-right:5px;
opacity:0;
display:none;


`



const StyledMailIcon = styled(IoMdMail)`
width:26px;
height:26px;
margin-top:2px;
transition: all .7s ease;
margin-right:5px;
opacity:0;
display:none;



`

const ABold = styled.a`
font-family: "Theano Didot", Regular;
font-weight:400;
line-height: 1.5em;
color:rgb(68, 68, 68) ;
font-size:1.6em;
opacity:1;
transition:all .7s ease;

:hover{
opacity:0.7;}

:hover {
${StyledMailIcon}{
//display:block;
//opacity:0.7;
}

${StyledPhoneIcon}{
//display:block;
//opacity:0.7;
}
}



`;

const PBold = styled.p`
font-family: "Theano Didot", Regular;
font-weight:bold;
line-height: 1.5em;
color:rgb(68, 68, 68);
margin-bottom:0px;
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
font-size:100%;
line-height: 1.4em;
color:rgb(68, 68, 68);
margin-bottom:0px;

`;

const PItalic = styled.p`
font-family: "Theano Didot", Regular;
font-size:180%;
font-style:italic;
line-height: 1.4em;
color:rgb(68, 68, 68);
margin-bottom:0px;

`;

const Div = styled.div`
margin-bottom:10px;
width:100%;
display:;flex;
justify-content:center;
align-items:center;
`


const ContinueButton = styled.div`
 cursor:pointer;
height:50px;
min-width:269px;
 display: flex;
 align-items:center;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:19px;
width:50%;
margin-left:auto;
margin-right:auto;
margin-top:4%;
font-family:${fonts.button};
letter-spacing:2px;
text-transform:uppercase;


:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;

}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;

}

:hover {
  color: #fff;



}

:hover:before {

  width: 100%;
}


  @media screen and (max-width: 666px) {
width:80%;
   }
`



interface EmailVerificationSuccessProps {
  className?: string
}

const EmailVerificationSuccessDiv: React.FC<EmailVerificationSuccessProps> = ({ }) => (
  <StyledDiv>

    <PItalic > <FormattedMessage id={'email_verify_1'}/> </PItalic>
    <P > <FormattedMessage id={'email_verify_2'}/> </P>
    <StyledLink to={'/buchen'}><ContinueButton><FormattedMessage id={'btn_book'}/></ContinueButton> </StyledLink>
    <StyledLink to={'/user'}><ContinueButton><FormattedMessage id={'btn_user'}/></ContinueButton> </StyledLink>

  </StyledDiv>

)

export default EmailVerificationSuccessDiv
